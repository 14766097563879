import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./GenericSessionCalendar.css";
import React, { useMemo } from 'react';
import { SessionScheduleView } from './SessionScheduleView'
import SessionCreator from './SessionCreator';
import { EventInfo } from '../../../../models/event/eventModel';
import { EventSessionCreationPermissionStatus } from '../../../../functions/Events';


// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

export interface CalSession
{
    bgg_title_link?: string,
    owner: string,
    id: number,
    name: string,
    title: string,
    start: Date,
    end: Date,
    info: string,
    room: string,
    table: number,
    slots_available: number,
    participants: string[],
    isBackgroundEvent?: boolean
}

export interface genericSessionCalendarStrings
{
    alreadySignedUp: string, 
    buttonSignUp: string, 
    buttonRelinquish: string,
    userNotLoggedIn: string, 
    userNotRegistered: string, 
    userNotRegisteredButton: string, 
    userNotPermittedToCreate: string, 
    userPermittedToCreate: string 
}

interface genericSessionCalendarProps
{
    seshList: CalSession[],
    onSignUp: (sesh: CalSession) => Promise<boolean>,
    onRelinquish: (sesh: CalSession) => Promise<boolean>,
    userStatus:  EventSessionCreationPermissionStatus,
    userId?: number,
    userName?: string,
    token?: string,
    event: EventInfo,
    displayStrings?: genericSessionCalendarStrings,
    onEventRSVP: () => Promise<boolean>
}

interface tableResource {
    id: number,
    title: string
}

const GenericSessionCalendar = (props: genericSessionCalendarProps) =>
{

    const user_status = props.userStatus;
    
    const display_strings = props.displayStrings ? props.displayStrings :
    {
        alreadySignedUp: "You are already signed up to this session.",
        buttonSignUp: "Click here to sign up to this session",
        buttonRelinquish: "Click here to relinquish slot.",
        userNotLoggedIn: "You are not logged in! Log into TGA to access RSVP features!",
        userNotRegistered: "You have not RSVPed to this event!",
        userNotRegisteredButton: "Click here to RSVP to this event!",
        userNotPermittedToCreate: "You are not yet permitted to create sessions for this event.",
        userPermittedToCreate: "You may now sign up for and create Sessions!"
    }

    const [selectedSession, setSelectedSession] = React.useState<CalSession|undefined>(undefined);
    const [seshRangeStart, setSeshRangeStart] = React.useState<moment.Moment>(props.event.startTime);
    const [seshRangeEnd, setSeshRangeEnd] = React.useState<moment.Moment>(props.event.endTime);
    const [tableResources, setTableResources] =React.useState<tableResource[]>([]);
    const [resourceView, setResourceView] =React.useState<boolean>(false);

    React.useEffect(()=>
    {
        const selsession = props.seshList.filter((sesh)=>{
            return selectedSession && (sesh.id === selectedSession.id)
        })
        if (selsession.length === 1)
        {
            setSelectedSession(selsession[0]);
        }
        else
        {
            setSelectedSession(undefined);
        }

    },[props.seshList])

    React.useEffect(() => {
        if(!props.event.lowestTableNumber || !props.event.highestTableNumber) {
            return;
        }
        let resourceBuilder = [];
        for(let i=props.event.lowestTableNumber; i <= props.event.highestTableNumber; i++) {
            resourceBuilder.push({id: i, title: "Table " + i.toString()});
        }
        setTableResources(resourceBuilder);
    },[])

    const evtList = props.seshList;

    const { startDate, endDate, views } = useMemo(
        () => ({
            startDate: props.event.startTime,
            endDate: props.event.endTime,
            views: {
                month: true,
                week: SessionScheduleView,
                day: true
            }
        }),
        []
    );

/* from titanbuster-event-page branch commit 94c159389ef4a4fbee16be05d9f52ff01c3f759e
    const eventSelected = (event: any): any =>
    {
        if(event.isBackgroundEvent === false)
        {
            const eventObj: CalEvent = event;
            setSelectedEvent(eventObj);
            setRangeStart(eventObj.start);
            setRangeEnd(eventObj.end);
        } 
    };
*/

    const seshSelected = (sesh: any): any =>
    {
        if(sesh.isBackgroundEvent !== false) {
            const seshObj: CalSession = sesh;
            setSelectedSession(seshObj);
            setSeshRangeStart(moment(seshObj.start));
            setSeshRangeEnd(moment(seshObj.end));
        } 
        
        
    };


    const slotSelected = (range: { start: Date, end: Date }):boolean =>
    {
        setSelectedSession(undefined);
        setSeshRangeStart(moment(range.start));
        setSeshRangeEnd(moment(range.end));
        return true;
    }


    //TODO: only display if multi-day Event.
    const backgroundEvent: any =
    {
        start: props.event.startTime,
        end: props.event.endTime
    }


    const showRSVP = (sesh: CalSession, user: string) =>
    {
        if(sesh.participants.includes(user))
        {
            return <div><b>{display_strings.alreadySignedUp}</b><button className="buttonPrimary" onClick={() => props.onRelinquish(sesh)}>{display_strings.buttonRelinquish}</button></div>
        }
        else
        {
            return <button
                className="buttonPrimary"
                onClick={() => props.onSignUp(sesh)}
            >{display_strings.buttonSignUp}</button>
        }
    }

    const onEventRsvp = () =>
    {
        props.onEventRSVP();
    }

    const user_header = (sta:  EventSessionCreationPermissionStatus) =>
    {
        switch (sta)
        {
            case  EventSessionCreationPermissionStatus.notAUser:
                return <b>{display_strings.userNotLoggedIn}</b>;
            case  EventSessionCreationPermissionStatus.notAttendingEvent:
                return <div><b>{display_strings.userNotRegistered}</b><button className="buttonPrimary" onClick={onEventRsvp}>{display_strings.userNotRegisteredButton}</button></div>;
            case  EventSessionCreationPermissionStatus.noSessionPermissions:
                return <b>{display_strings.userNotPermittedToCreate}</b>
            case  EventSessionCreationPermissionStatus.canCreateSessions:
                return <b>{display_strings.userPermittedToCreate}</b>;
            default:
                return <b>Something Bad has occured if you're seeing this.</b>

        }

    }


    const editPermitted = () =>
    {
        if (selectedSession && (selectedSession.owner === props.userName || props.event.owner == props.userName))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const generateTitle = (evt: CalSession) => 
    {
        return evt.table ? "Table " + evt.table.toString() + ": " + evt.name : "";
    }


    return <div>
        <div>
            {user_header(user_status)}
            <p></p>
        </div>
        <button className="buttonPrimary" onClick={() => setResourceView(!resourceView)}>Change View</button>
        <p></p>
        <div className="myCustomHeight">
            {resourceView ? 
            <Calendar
                date={startDate.toDate()}
                min={startDate.toDate()}
                max={endDate.toDate()}
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                view="week"
                views={views}
                events={evtList}
                onSelectEvent={seshSelected}
                toolbar={false}
                onSelecting={slotSelected}
                titleAccessor={generateTitle}
                resources={tableResources}
                resourceAccessor={'table'}
            /> :
            <Calendar
                date={startDate.toDate()}
                min={startDate.toDate()}
                max={endDate.toDate()}
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                view="week"
                views={views}
                events={evtList}
                onSelectEvent={seshSelected}
                toolbar={false}
                onSelecting={slotSelected}
                titleAccessor={generateTitle}
            />}
        </div>
        <br></br>
        {selectedSession && <div>
            <b>Selected Session: </b>
            <p> {selectedSession.name}</p>
            <b>Start Time: </b>
            <p> {selectedSession.start.toLocaleString()}</p>
            <b>End Time: </b>
            <p> {selectedSession.end.toLocaleString()}</p>
            {selectedSession.bgg_title_link && <div>
                <b>BGG Game Link:</b><br></br><br></br>
                <a href={selectedSession.bgg_title_link} target="_blank" rel="noreferrer">{selectedSession.bgg_title_link}</a>
            </div>}
            <br></br>
            <b>Description:</b>
            <p> {selectedSession.info}</p>
            <b>Table:</b>
            <p> {selectedSession.table}</p>
            <b>Session Host:</b>
            <p> {selectedSession.owner}</p>
            <b>Slots Available:</b>
            <p> {selectedSession.slots_available}</p>
            <b>Current Participants:</b>
            <p> {selectedSession.participants.join(' ,')}</p>
            {props.userStatus > 1 && props.userName && showRSVP(selectedSession, props.userName)}
        </div>}
        <br></br>
        <hr></hr>
        <br></br>
        {props.userStatus > 2 && props.userId && props.token && !editPermitted() && <SessionCreator evtId={props.event.id} userId={props.userId} token={props.token} setStart={seshRangeStart} setStop={seshRangeEnd} lowestTable={props.event.lowestTableNumber ? props.event.lowestTableNumber : 0} highestTable={props.event.highestTableNumber ? props.event.highestTableNumber : 0} maxRSVP={props.event.slots ? props.event.slots : 0}/>}
        {props.userStatus > 2 && props.userId && props.token && editPermitted() && selectedSession && <SessionCreator evtId={props.event.id} userId={props.userId} token={props.token} defaults={selectedSession} editSessionId={selectedSession.id} key={selectedSession.id} setStart={seshRangeStart} setStop={seshRangeEnd} lowestTable={props.event.lowestTableNumber ? props.event.lowestTableNumber : 0} highestTable={props.event.highestTableNumber ? props.event.highestTableNumber : 0} maxRSVP={props.event.slots ? props.event.slots : 0}/>}
    </div>
}

export default GenericSessionCalendar;