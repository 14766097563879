import React, { useEffect, useState } from 'react'
import {
  useForm, FieldValues, SubmitHandler, Controller
} from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import Select, { SingleValue } from 'react-select';
import { ErrorMessage } from "@hookform/error-message"
import { EventCreationDetails, EventSeriesProps} from '../types/eventModel';
import { EventInfo } from '../../../../models/event/eventModel';
import '../styles/EventForm.css'
import { getAllVenues, GETAllVenues_Single, VenueDetails } from "../../venues/venueModels";
import { VenueMap } from '../../venues/components';
import DateTimePicker from 'react-datetime-picker';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import '../styles/TimePicker.css';
import '../styles/DatePicker.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { fetchSeriesById, fetchUserById, fetchVenues, fetchEventAttendees } from '../../../../utils';
import { VenueSelector } from '../../venues/components/VenueSelector';
import privateVenueMapPlaceholder from '../files/simple-house.jpg'
import { UserInfo } from 'os';
import { UserDetails } from '../../../../models/user/userInfo';
import '../../venues/index.css'; // is this the right style sheet?
import { isValueType } from 'react-date-picker/dist/cjs/shared/propTypes';
import { MoonLoader } from 'react-spinners'
import { getVenueDetails } from '../../venues/venueModels';

export interface neweventFormProps {
  loggedInUser?: number,
  authToken?: string,
  containerClassname?: string,
  event?: EventInfo,
  series?: EventSeriesProps
  onRequestEdit?: ()=>void,
  onSubmitEvent: (event: EventCreationDetails | EventInfo) => any,
  onSubmitSeries: (event: EventSeriesProps | EventCreationDetails) => any,
  onCancel: () => void,
  onDelete?: (requestBody: EventSeriesProps | EventInfo) => Promise<boolean>,
  onRSVPToggle: (eid: number) => Promise<boolean>,
  readonly?: Boolean,
  disableRsvp ?: Boolean,
  noReset ?: Boolean,
}

export const NewEventForm = (props : neweventFormProps) => {

  var nowDate = new Date();

  const defaultInstance : EventInfo = {
    id: 0,
    venue: 0,
    name: 'My Event',
    info: '',
    slots: 20,
    websiteUrl: '',
    startTime: nowDate,
    endTime: nowDate,
    lowestTableNumber: 1,
    highestTableNumber: 4,
    owner: '',
    mainMapImage: ''
  }

  const defaultSeriesInstance : EventSeriesProps = {
    id: 0,
    venue: 0,
    owner: props.loggedInUser ? props.loggedInUser : 0,
    name: 'My Event',
    info: '',
    slots: 20,
    websiteUrl: '',
    eventStartTime: nowDate,
    eventEndTime: nowDate,
    eventsStart: nowDate,
    eventsEnd: nowDate,
    recurrencePattern: 'ONCE',
    recurrenceSeperation: 0,
    lowestTableNumber: 1,
    highestTableNumber: 4
  }

  const seriesStart = props.series?.id ? props.series : defaultSeriesInstance

  const [isLoading, setIsLoading] = useState(true)
  const [formDataLoaded, setFormDataLoaded] = useState(false)
  const [venueOptions, setVenuesOptions] = useState<{label: string; value: VenueDetails;}[]>([])
  const [seriesInstance, setSeriesInstance] = useState<EventSeriesProps>(seriesStart);
  const [instance, setInstance] = useState<EventInfo>(props.event ? props.event : defaultInstance)
  const [userDetails, setUserDetails] = useState<UserDetails>()
  const [selectedVenue, setSelectedVenue] = useState<VenueDetails | undefined>()
  const [eventAttendees, setEventAttendees] = useState<string[]|undefined>()
  const [isRecurring, setIsRecurring] = useState(false)
  const [isEditingSeries, setIsEditingSeries] = useState(true) // Are we editing an EventInfo or an EventSeriesProps?
  const [deleteConfirmDisplay, setDeleteConfirmDisplay] = useState<boolean>(false);
  const dateStringFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  useEffect(() => setSeriesInstance(props.series?.id ? props.series : defaultSeriesInstance),[props.series])

  useEffect(()=>setIsRecurring(getValues('recurrencePattern') !== 'ONCE'))

  const getDateString = (d: Date) => {return (d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2,"0") + '-' + d.getDate().toString().padStart(2,"0"))};

  const defaultValues = {
    name: 'My Event',
    info: '',
    detailed_directions: '',
    slots: 20,
    websiteUrl: '',
    eventStartTime: "9:00",
    eventEndTime: "17:00",
    instanceStartTime: nowDate.toISOString(),
    instanceEndTime: nowDate.toISOString(),
    eventsStart: nowDate.toISOString(),
    eventsEnd: nowDate.toISOString(),
    venueOption:null,
    recurrencePreset: { label: 'One time only', value: {recurrencePattern:'ONCE', recurrenceSeperation:undefined}},
    recurrencePattern: 'ONCE',
    recurrenceSeperation: 0,
    lowestTableNumber: 1,
    highestTableNumber: 4
  }

  const {
    formState: { errors, isValid }, getValues, register, setValue: setFormValue, handleSubmit, control, reset
  } = useForm<FieldValues>(
    {
      defaultValues: defaultValues,
      mode: 'onBlur'
    },
  );

  const baseRecurrenceSelectOptions = [
    { label: 'daily', value: {recurrencePattern:'DAILY', recurrenceSeperation:0}},
    { label: 'weekly', value: {recurrencePattern:'WEEKLY', recurrenceSeperation:0}},
    { label: 'biweekly', value: {recurrencePattern:'WEEKLY', recurrenceSeperation:1}},
    { label: 'monthly', value: {recurrencePattern:'MONTHLY', recurrenceSeperation:0} },
  ]

  const createRecurrenceSelectOptions = [
    { label: 'one time only', value: {recurrencePattern:'ONCE', recurrenceSeperation:undefined}},
    { label: 'daily', value: {recurrencePattern:'DAILY', recurrenceSeperation:0}},
    { label: 'weekly', value: {recurrencePattern:'WEEKLY', recurrenceSeperation:0}},
    { label: 'biweekly', value: {recurrencePattern:'WEEKLY', recurrenceSeperation:1}},
    { label: 'monthly', value: {recurrencePattern:'MONTHLY', recurrenceSeperation:0} },
  ]

  const recurrenceSelectOptions = isEditingSeries ? baseRecurrenceSelectOptions : createRecurrenceSelectOptions;

  const getEditingSeriesSelectOptions = (sers :EventSeriesProps) => {
    if (sers.id !==0) return [{ label: 'Editing event series for', value: 'true' }, { label: 'Editing single event', value: '' }];
    return [{ label: 'Editing single event', value: '' }]
  }

  const loadEventAttendees = ()=>{
    if(instance.id !==0){
      fetchEventAttendees(instance.id).then((r)=>{
        setEventAttendees(r)
    })
    }
  }

  const loadFormData = () => {
    // Fetches list of venues, and the event owner's username
    setIsLoading(true)

    const userFetch = new Promise<UserDetails>((resolve, reject) => props.loggedInUser ? resolve(fetchUserById(props.loggedInUser, props.authToken)) : reject('No Logged In User'))
      .then((user) => {
        setUserDetails(user);
      })
      .catch((reason) => {
        console.error(`Error Loading newEventFormData: ${JSON.stringify(reason)}`)
      })

    const venueFetch =fetchVenues(props.authToken)
      .then((venues) => {
      setVenuesOptions(venues.map(v=>({label:v.name, value:v})))
      })
      .catch((reason) => {
        console.error(`Error Loading newEventFormData: ${JSON.stringify(reason)}`)
      })
    
    Promise.all([userFetch, venueFetch])
      .then(() => setFormDataLoaded(true))
      .catch(() => setFormDataLoaded(false))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    loadFormData()
    if (props.authToken && props.loggedInUser) {
    } else {
      console.error('Creating or editing events requires event host permission.')
    }
  }, [props.event, props.authToken, props.loggedInUser])




  
  useEffect(() => {
    //We should always assume we're updating the instance First.
    //Otherwise, we'll need a new prop
    setIsEditingSeries(false)
  }, [])





  useEffect(() => {
    // Populate form values with existing data (if any)
    if (seriesInstance && isEditingSeries) {
      // Editing existing series
      setFormValue('highestTableNumber', seriesInstance.highestTableNumber)
      setFormValue('info', seriesInstance.info)
      setFormValue('lowestTableNumber', seriesInstance.lowestTableNumber)
      setFormValue('name', seriesInstance.name)
      setFormValue('slots', seriesInstance.slots)
      const seriesVenue = venueOptions.find(o=>o.value.id === seriesInstance?.venue)?.value
      setSelectedVenue(seriesVenue)
      setFormValue('venueOption', {label:seriesVenue?.name, value:seriesVenue})
      setFormValue('websiteUrl', seriesInstance.websiteUrl)
      setFormValue('eventStartTime', seriesInstance.eventStartTime)
      setFormValue('eventEndTime', seriesInstance.eventEndTime)
      setFormValue('recurrencePreset', recurrenceSelectOptions.find(o=>o.value.recurrencePattern === seriesInstance.recurrencePattern && o.value.recurrenceSeperation === seriesInstance.recurrenceSeperation))
      setFormValue('recurrencePattern', seriesInstance.recurrencePattern)
      setFormValue('recurrenceSeperation', seriesInstance.recurrenceSeperation)
      setFormValue('eventsStart', seriesInstance.eventsStart) //add a time to make datestring represent localtime
      setFormValue('eventsEnd', seriesInstance.eventsEnd) //add a time to make datestring represent localtime
      setIsRecurring(!(seriesInstance.recurrencePattern === 'ONCE'))
    } else if (instance && !isEditingSeries) {
      // Editing existing instance
      console.log(instance.startTime)
      console.log(instance.endTime)
      setFormValue('highestTableNumber', instance.highestTableNumber)
      setFormValue('info', instance.info)
      setFormValue('lowestTableNumber', instance.lowestTableNumber)
      setFormValue('name', instance.name)
      setFormValue('slots', instance.slots)
      const instanceVenue = venueOptions.find(o=>o.value.id === instance?.venue)?.value
      setSelectedVenue(instanceVenue)
      setFormValue('venueOption', {label: instanceVenue?.name, value:instanceVenue})
      setFormValue('websiteUrl', instance.websiteUrl)
      setFormValue('instanceStartTime', new Date(instance.startTime))
      setFormValue('instanceEndTime', new Date(instance.endTime))
    }

    // Else, creating new event, use form defaults
  }, [seriesInstance, setFormValue, instance, formDataLoaded, isEditingSeries])





  const handleVenueSelect = (o:{label:string, value: VenueDetails}) => {
    setSelectedVenue(o.value)
  }





  const handleRecurrencePatternChange = (e: any) => {
    setIsRecurring(e.value.recurrencePattern !== 'ONCE')
    setFormValue('recurrencePattern', e?.value.recurrencePattern)
    setFormValue('recurrenceSeperation', e?.value.recurrenceSeperation)
    const eventsStartDate = new Date(getValues('eventsStart'))
    switch (e.value.recurrencePattern) {
      case 'ONCE':
        setFormValue('eventsEnd', getValues('eventsStart'))
        break;
      case 'DAILY':
        const dateForNextDay = new Date(eventsStartDate.getTime() + 1 * 24 * 60 * 60 * 1000)
        setFormValue('eventsEnd', dateForNextDay.toISOString())
        break;
      case 'WEEKLY':
        const dateForNextWeek = new Date(eventsStartDate.getTime() + 7 * 24 * 60 * 60 * 1000)
        setFormValue('eventsEnd', dateForNextWeek.toISOString())
        break;
      case 'BIWEEKLY':
        const dateForTwoWeeks = new Date(eventsStartDate.getTime() + 14 * 24 * 60 * 60 * 1000)
        setFormValue('eventsEnd', dateForTwoWeeks.toISOString())
        break;
    }
  }





  const handleChangeBetweenEditingInstanceOrSeries = async (o:any)=>{
    const val = !!o?.value
    if(val){
      // now editing series
      await loadFormData()
      setIsEditingSeries(val)
    }else{
      // now editing instance
      setIsEditingSeries(val)
    }
  }





  const localOnSubmit = (fields: FieldValues) => {
    console.log(fields);
    /* transforms field values to EventSeriesProps or
      EventCreationSchema before calling props.onSubmit */
    if (isEditingSeries && seriesInstance) {
      // Editing existing event series, submit EventSeriesProps
      props.onSubmitSeries({
        id: seriesInstance.id,
        info: fields.info,
        name: fields.name,
        owner: seriesInstance.owner,
        slots: Number(fields.slots),
        eventsStart: fields.eventsStart,
        eventsEnd: fields.eventsEnd,
        eventStartTime: fields.eventStartTime,
        eventEndTime: fields.eventEndTime,
        recurrencePattern: fields.recurrencePattern,
        recurrenceSeperation: fields.recurrenceSeperation,
        venue: fields.venueOption.value.id,
        websiteUrl: fields.websiteUrl,
        lowestTableNumber: Number(fields.lowestTableNumber), // To be implemented
        highestTableNumber: Number(fields.highestTableNumber), // To be implemented
      } as EventSeriesProps)
    } else if (!isEditingSeries && instance) {
      // Editing instance event, submit EventInfo
      props.onSubmitEvent({
        endTime: fields.instanceEndTime,
        highestTableNumber: Number(fields.highestTableNumber), // To be implemented
        id: instance.id,
        info: fields.info,
        lowestTableNumber: Number(fields.lowestTableNumber),
        name: fields.name,
        owner: instance.owner,
        slots: Number(fields.slots),
        seriesId: instance.seriesId,
        startTime: fields.instanceStartTime,
        venue: fields.venueOption.value.id,
        websiteUrl: fields.websiteUrl
      } as EventInfo)
    } else {
      // Creating new event, submit EventCreationDetails
      if(isRecurring) {
        props.onSubmitSeries({
          info: fields.info,
          name: fields.name,
          slots: Number(fields.slots),
          eventsStart: fields.eventsStart.split("T")[0],
          eventsEnd: (fields.recurrencePattern === 'ONCE') ? fields.eventsStart.split("T")[0] : fields.eventsEnd.split("T")[0], // Set eventsEnd = eventsStart for non-recurring event
          eventStartTime: fields.eventStartTime,
          eventEndTime: fields.eventEndTime,
          recurrencePattern: fields.recurrencePattern,
          recurrenceSeperation: fields.recurrenceSeperation,
          venue: fields.venueOption.value.id,
          websiteUrl: fields.websiteUrl,
          lowestTableNumber: Number(fields.lowestTableNumber), 
          highestTableNumber: Number(fields.highestTableNumber),
          rsvp: fields.rsvp
        } as EventCreationDetails)
      } else {
        props.onSubmitEvent({
          info: fields.info,
          name: fields.name,
          slots: Number(fields.slots),
          eventsStart: fields.eventsStart.split("T")[0],
          eventsEnd: (fields.recurrencePattern === 'ONCE') ? fields.eventsStart.split("T")[0] : fields.eventsEnd.split("T")[0], // Set eventsEnd = eventsStart for non-recurring event
          eventStartTime: fields.eventStartTime,
          eventEndTime: fields.eventEndTime,
          recurrencePattern: fields.recurrencePattern,
          recurrenceSeperation: fields.recurrenceSeperation,
          venue: fields.venueOption.value.id,
          websiteUrl: fields.websiteUrl,
          lowestTableNumber: Number(fields.lowestTableNumber), 
          highestTableNumber: Number(fields.highestTableNumber),
          rsvp: fields.rsvp
        } as EventCreationDetails)
      }
    }
    reset(defaultValues)
  }





  const localOnDelete = (event:EventInfo | EventSeriesProps)=>{
    if(props.onDelete){
      if(isEditingSeries && seriesInstance){
        props.onDelete(seriesInstance)
      }else{
        props.onDelete(event)
      }
    }
  }





  const localHandleRSVPToggle =()=>{
    if(instance){
      props.onRSVPToggle(instance.id).then(()=>(
        loadEventAttendees()
      ))
    }else{
      console.error('newEventForm.localHandleRSVPToggle: event instance not found')
    }
  }





  if(isLoading){
    return (
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%', width:'100%'}}>
        <MoonLoader/>
      </div>
    )
  }
  




  return (
    <form
      onSubmit={handleSubmit(localOnSubmit)}
      className={props.containerClassname || 'event-form'}
    >
      <div className='event-form-first-row-container'>
        {props.event && !props.readonly ?
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                flexGrow: 1,
                width: 250,
                marginRight: 8
              }),
            }}
            defaultValue={{ label: 'Editing event series for', value: 'true' }}
            options={getEditingSeriesSelectOptions(seriesInstance)}
            onChange={(o:any)=>handleChangeBetweenEditingInstanceOrSeries(o)}
            value={isEditingSeries ?
              { label: 'Editing event series for', value: 'true' } :
              { label: 'Editing single event', value: '' }
            }
          />
          :
          !props.event && <h4 className='controls-row' style={{ marginRight: 8 }}>Creating new event</h4>
        }
        {!props.readonly ? <input
          style={{ textAlign: 'center', width: '75%', height: 30 }}
          className='event-form-item'
          type="text"
          placeholder="Event Name"
          {...register("name", {
            required: 'Event name is required'
          })}
        /> : <h3 className='controls-row read-only-field' style={{ marginRight: 8 }}>{props.event?.name}</h3>}
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => <p className='helper-text'>{message}</p>}
        />
      </div>
      <div className='event-form-second-row-container'>
        {isEditingSeries &&
          <div id='event-form-time-controls-for-series' className='controls-row'>
            <h4 style={{ textAlign: 'center' }}>from</h4>
            {!props.readonly ? <Controller
              control={control}
              name='eventStartTime'
              render={({ field:{onChange, value} }) => (
                <TimePicker clockIcon={null} onChange={onChange} value={value} />
              )}
            /> : <h4 className='read-only-field'>{(props.series as EventSeriesProps)?.eventStartTime?.toLocaleTimeString()}</h4>}
            <h4 style={{ textAlign: 'center' }}>to</h4>
            {!props.readonly ? <Controller
              control={control}
              name='eventEndTime'
              render={({ field:{value, onChange} }) => (
                <TimePicker clockIcon={null} onChange={e=> e ? onChange(e) : undefined} value={value} />
              )}
            /> : <h4 className='read-only-field'>{(props.series as EventSeriesProps)?.eventEndTime?.toLocaleTimeString()}</h4>}
            <h4 style={{ textAlign: 'center' }}>{isEditingSeries ? "starting on" : "on"}</h4>
            {!props.readonly ? <Controller
              control={control}
              name='eventsStart'
              render={({ field:{value, onChange}}) => (
                <DatePicker locale="en-US" onChange={e=> e ? onChange((e as Date).toISOString()) : undefined} value={value} />
              )}
            /> : <h4 className='read-only-field'>{(props.series as EventSeriesProps)?.eventsStart?.toDateString()}</h4>}
          </div>
        }
        {!isEditingSeries &&
          <div id='event-form-time-controls-for-instance' className='controls-row'>
            <h4 style={{ textAlign: 'center' }}>from</h4>
            {!props.readonly ? <Controller
              control={control}
              name='instanceStartTime'
              render={({ field:{value, onChange}}) => {
                return (
                  <DateTimePicker locale="en-US" onChange={onChange} value={value} />
                )
              }}
            /> : <h4 className='read-only-field'>{getValues('instanceStartTime').toLocaleString()}</h4>}
            <h4 style={{ textAlign: 'center' }}>to</h4>
            {!props.readonly ? <Controller
              control={control}
              name='instanceEndTime'
              render={({ field:{value, onChange}}) => {
                return (
                  <DateTimePicker locale="en-US" onChange={onChange} value={value} />
                )
              }}
            /> : <h4 className='read-only-field'>{getValues('instanceEndTime').toLocaleString()}</h4>}
          </div>
        }
      </div>

      <div className='event-form-third-row-container'>
        {isEditingSeries &&
          <div id='second-row-controls-series' className='controls-row'>
            <div className='controls-row'>
              <h4 style={{ textAlign: 'center' }}>occurring</h4>
              <Controller
                control={control}
                name='recurrencePreset'
                render={({ field:{onChange, value} }) => {
                  return (
                    <Select
                      options={recurrenceSelectOptions}
                      defaultValue={{ label: 'One time only', value: {recurrencePattern:'ONCE', recurrenceSeperation:0}}}
                      onChange={(e)=>{onChange(e); handleRecurrencePatternChange(e)}}
                      value={value}
                    />
                  )
                }}
              />
            </div>

            {isRecurring &&
              <div className='controls-row'>
                <h4 style={{ textAlign: 'center' }}>until</h4>
                <Controller
                  control={control}
                  name='eventsEnd'
                  render={({ field:{onChange, value} }) => (
                    <DatePicker locale="en-US" onChange={e=>e ? onChange((e as Date).toISOString()) : undefined} value={value} />
                  )}
                />
              </div>
            }
          </div>
        }
        <div className='controls-row' style={{ flexGrow: 1 }}>
          <h4>at</h4>
          { !props.readonly ? <Controller
            rules={{ required: true }}
            control={control}
            name='venueOption'
            render={({ field:{value, onChange} }) => {
              return (
                <Select
                  classNames={{
                    input:()=>('react-select-input'),
                    control:()=>('react-select-control'),
                    container:()=>('react-select-container')
                  }}
                  value={value}
                  placeholder="Select a venue..."
                  blurInputOnSelect
                  captureMenuScroll
                  id='venue-select'
                  onChange={(o:{label:string, value:VenueDetails})=>{onChange(o); handleVenueSelect(o)}}
                  options={venueOptions}
                />
              )
            }}
          /> 
          : <h4 className='read-only-field'>{(props.event && props.event.name === "Titan Buster") ? "Private Venue" : selectedVenue?.name}</h4>
          }
        </div>
      </div>
      <div className='event-form-fields'>
        { !props.readonly ? <textarea
          style={{ flexGrow: 1 }}
          className='event-form-item'
          placeholder='Introduction and Description'
          {...register("info", {
            required: false
          })}
        /> : <p className="read-only-field" style={{height:'100%', margin:0}}>{getValues('info')}</p>}
        <label>
          {`Hosted By: ${userDetails?.user_name}`}
        </label>
        <label>
          Max Participants:{
          !props.readonly ? <div style={{ display: 'inline-block' }}>

            <input
              type="number"
              min="1"
              max="100"
              className='event-form-item'
              style={{ textAlign: 'center', width: 60 }}
              {...register("slots", {
              })}
            >
            </input>
            
          </div> : <span className='read-only-field'>{getValues('slots')}</span>}
        </label>
        <label>
          Lowest Table Number:{
          !props.readonly ? <div style={{ display: 'inline-block' }}>

            <input
              type="number"
              min="1"
              max="25"
              className='event-form-item'
              style={{ textAlign: 'center', width: 60 }}
              {...register("lowestTableNumber", {
              })}
            >
            </input>
            
          </div> : <span className='read-only-field'>{getValues('lowestTableNumber')}</span>}
        </label>
        <label>
          Highest Table Number:{
          !props.readonly ? <div style={{ display: 'inline-block' }}>

            <input
              type="number"
              min="1"
              max="25"
              className='event-form-item'
              style={{ textAlign: 'center', width: 60 }}
              {...register("highestTableNumber", {
              })}
            >
            </input>
            
          </div> : <span className='read-only-field'>{getValues('highestTableNumber')}</span>}
        </label>
        { !isEditingSeries &&
        <div>
          <label>
            {`Attendees: `}
          </label>
          <em>
            {eventAttendees}
          </em>
        </div>

        }
      </div>
      <div className='event-form-venue'>
        {
          //TODO: Remove Hardcoded reliance on Titan Buster
          !selectedVenue || (selectedVenue?.private) || (props.event && props.event.name === "Titan Buster") ?
            <div className='event-form-venue-placeholder'>
              <img className='event-form-venue-placeholder-image' src={privateVenueMapPlaceholder} />
              {selectedVenue || getValues('venueOption') ?
                <h4 style={{ textAlign: 'center', margin: '.25em', whiteSpace: 'pre-wrap' }}>{"This is a private venue. \n Please message event host for address."}</h4>
                :
                <h4 style={{ textAlign: 'center', margin: '.25em', whiteSpace: 'pre-wrap' }}>{"No venue selected. Please select a venue."}</h4>
              }
            </div>
            :
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <h4 style={{ textAlign: 'center' }}>{selectedVenue?.agg_address}</h4>
              <VenueMap containerClassName='event-form-venue-map' venue={selectedVenue} />
            </div>
        }
      </div>
      {!props.readonly ? <div className='event-form-controls'>
      {!deleteConfirmDisplay && <div className='event-form-controls'>
        { (props.event?.id || props.series?.id) &&
        <div>
        <button
          type='button'
          className='event-form-button'
          onClick={() => setDeleteConfirmDisplay(true)}
        >
          {isEditingSeries ? "Delete Event Series" : "Delete Event"}
        </button>
        </div>
        }
        <button
          type='button'
          value='Cancel'
          className='event-form-button'
          onClick={()=>{!props.noReset && reset(defaultValues); props.onCancel()}}
        >
          Cancel
        </button>
        <button
          disabled={!isValid}
          style={{ backgroundColor: isValid ? 'grey' : 'darkgrey'}}
          value='Submit'
          className='event-form-button'
          type="submit"
        >
          {isValid ? 'Submit' : <abbr title='All fields are required'>Submit</abbr>}
        </button>
      </div>}
      {deleteConfirmDisplay && <div className='event-form-controls'>
          <p>Are you sure you want to delete?</p>
          <button
            type='button'
            className='event-form-button'
            style={{ backgroundColor: '#ae2515' , color: 'white' }}
            onClick={()=>props.event ? localOnDelete(props.event):console.warn('onDelete missing event or handler.')}
          > Yes </button>
          <button
            type='button'
            className='event-form-button'
            onClick={()=>setDeleteConfirmDisplay(false)}
          > No </button>
          </div>}
      </div>
      :
      <div className='event-form-controls'>
        { props.loggedInUser && userDetails?.user_name === props.event?.owner &&
          <button
            type='button'
            className='event-form-button'
            onClick={ props.onRequestEdit ? props.onRequestEdit : ()=>console.warn('newEventForm onRequestEdit called, but not defined')}
          >
            Edit
          </button>
        }
        { props.loggedInUser && !props.disableRsvp &&
          <button
            type='button'
            className='event-form-button'
            onClick={localHandleRSVPToggle}
          >
            {userDetails?.user_name && eventAttendees?.includes(userDetails.user_name) ? 'Cancel RSVP' : 'RSVP'}
          </button>
        }
      </div>}
    </form>
  )
}

export default NewEventForm