import { UserDetails, getUserDetails } from '../user/userInfo';
import moment from 'moment';

export interface DB_Error {
    result: string,
    reason: string,
    exception?: string,
    message?: string
}

export type ValuePiece = Date | null;

export type Value = any;//ValuePiece | [ValuePiece, ValuePiece]

export interface EventCreationDetails {
    start: Value,
    end: Value,
    name: string,
    info: string,
    venue: number,
    slots: number,
    owner: number,
    website_url: string,
    highest_table_number: number,
    lowest_table_number: number,
    rsvp?: boolean
}

export interface EventInfo {
    id: number,
    name: string,
    venue: number,
    startTime: Value,
    endTime: Value,
    info: string,
    websiteUrl: string,
    slots: number,
    highestTableNumber: number,
    lowestTableNumber: number,
    owner: string,
    seriesId?: number,
    mainMapImage: string,
}

interface EventInfoDB {
    [key: string] : number | string | null,
    id: number,
    name: string,
    venue: number | null,
    start_time: string,
    end_time: string,
    info: string | null,
    website_url: string | null,
    slots: number | null,
    lowest_table_number: number | null,
    highest_table_number: number | null,
    owner: number | null,
    series_id: number | null,
    main_map_image: string | null,
}

export async function makeEventInfo(res: EventInfoDB): Promise<EventInfo> {
    const default_values : {[key: string]: number} = {
        venue: 1,
        slots: 20,
        lowest_table_number: 1,
        highest_table_number: 4,
    }

    for (let k of Object.keys(default_values)) {
        if (!res.hasOwnProperty(k))
            console.warn(`Event id ${res.id} missing ${k}. Using default value ${default_values[k]}.`) 
    }

    const startTimegmt = moment.tz(res.start_time, "GMT");
    const endTimegmt = moment.tz(res.end_time, "GMT");

    const eventInfo: EventInfo = {
        id: res.id,
        name: res.name,
        venue: (res.venue !== null) ? res.venue : default_values.venue,
        startTime: startTimegmt.clone().tz('America/New_York'),
        endTime: endTimegmt.clone().tz('America/New_York'),
        info: (res.info !== null) ? res.info : "",
        websiteUrl: (res.website_url !== null) ? res.website_url : "",
        slots: (res.slots !== null) ? res.slots : default_values.slots,
        highestTableNumber: (res.highest_table_number !== null) ? res.highest_table_number : default_values.highest_table_number,
        lowestTableNumber: (res.lowest_table_number !== null) ? res.lowest_table_number : default_values.lowest_table_number,
        owner: (res.owner !== null) ? await getUserDetails(res.owner).then(usr => (usr === undefined ? "" : usr.user_name)) : "",
        mainMapImage: (res.main_map_image !== null) ? res.main_map_image : "",
        ...(res.series_id) && {seriesId: res.series_id},
    }
    return eventInfo;
}

export interface getAllEventsResponse {
    result: string,
    data: EventInfoDB[]
}

function isGetAllEventsResponse(resp: getAllEventsResponse | DB_Error): resp is getAllEventsResponse {
    return (resp as getAllEventsResponse).result === 'success';
}

interface getSpecificEventResponse {
    result: string,
    data: EventInfoDB
}

function isGetSpecificEventResponse(resp: getSpecificEventResponse | DB_Error): resp is getSpecificEventResponse {
    return (resp as getSpecificEventResponse).result === 'success';
}

export interface eventRsvpList {
    count: number,
    data: UserDetails[]
}

interface getRsvpListResponse extends eventRsvpList {
    result: string
}

function isGetRsvpListResponse(resp: getRsvpListResponse | DB_Error): resp is getRsvpListResponse {
    return (resp as getRsvpListResponse).result === 'success';
}

export interface EventType{
    id: number,
    name: string
}

export const getAllEvents = (from?: Date, to?: Date): Promise<EventInfo[] | undefined> => {
    console.log("Getting all events");
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    let queryParams = from === undefined ? "" : "from=" + encodeURIComponent(from.toISOString());
    queryParams += to === undefined ? "" : "to=" + encodeURIComponent(to.toISOString());
    console.log("queryParams: " + queryParams);
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event2' + (queryParams && "?" + queryParams), requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getAllEvents')
                console.log(`HTTP Response Code: ${response.status}`)
                throw ('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then((res: DB_Error | getAllEventsResponse) => {
            if (isGetAllEventsResponse(res)) {
                return res.data;
            } else {
                console.log(`Backend Error in getAllEvents Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .then((res) => {
            if (!res) {
                return;
            }
            return Promise.all(res.map(makeEventInfo));
        })
        .catch((reason) => {
            console.log('Fetch Exception in getAllEvents')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const getSpecificEvent = (eventId: number): Promise<EventInfo | undefined> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event2/single?id=${encodeURIComponent(eventId)}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getSpecificEvent')
                console.log(`HTTP Response Code: ${response.status}`)
                throw ('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then((res: DB_Error | getSpecificEventResponse) => {
            if (isGetSpecificEventResponse(res)) {
                return res.data;
            } else {
                console.log(`Backend Error in getSpecificEvent Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .then( async (res) => {
            if (!res) {
                return;
            }
            const evtInfo = await makeEventInfo(res);
            return evtInfo;
        })
        .catch((reason) => {
            console.log('Fetch Exception in getSpecificEvent')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const getRSVPsToEvent = (eventId: number): Promise<eventRsvpList | undefined> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' },
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/eventUsers/event/' + eventId, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getRSVPsToEvent')
                console.log(`HTTP Response Code: ${response.status}`)
                throw ('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then((res: DB_Error | getRsvpListResponse) => {
            if (isGetRsvpListResponse(res)) {
                return res as eventRsvpList;
            } else {
                console.log(`Backend Error in getRSVPsToEvent Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in getRSVPsToEvent')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const createEvent = (input: EventCreationDetails, authToken: string): Promise<boolean> => {
    const requestBody = {
        start_time: input.start,
        end_time: input.end,
        ...input
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event2/create', requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.status && res.status === `success`) {
                return true;
            } else {
                console.log(`Backend Error in CreateEvent`);
                res.reason && console.log(`Reason: ${res.reason}`);
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in CreateEvent')
            console.log(`Reason: ${reason}`)
            return false;
        })
}

export const updateEvent = (input: EventInfo, authToken: string): Promise<boolean> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(input)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event2/update' , requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.result && res.result === `success`) {
                return true;
            } else {
                console.log(`Backend Error in UpdateVenue`);
                res.reason && console.log(`Reason: ${res.reason}`);
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in UpdateVenue')
            console.log(`Reason: ${reason}`)
            return false;
        })
}

export const isUserOwnerofEvent = (eid: number, uid: number, authToken: string): Promise<boolean> => {
    const requestBody = {
        uid: uid
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + `/tga/event/ocheck/` + eid, requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.status && res.status === `success`) {
                return res.result;
            } else {
                console.log(`Backend Error in UpdateVenue`);
                res.reason && console.log(`Reason: ${res.reason}`);
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in UpdateVenue')
            console.log(`Reason: ${reason}`)
            return false;
        })
}